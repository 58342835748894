import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/import',
      name: 'import',
      component: () => import('@/views/users/import.vue')
    },
    {
      path: '/posts',
      name: 'posts',
      component: () => import('@/views/posts.vue')
    },
    {
      path: '/problems',
      name: 'problems',
      component: () => import('@/views/problems.vue')
    },
    {
      path: '/orders-1',
      name: 'orders-1',
      component: () => import('@/views/orders-1.vue')
    },
    {
      path: '/orders-2',
      name: 'orders-2',
      component: () => import('@/views/orders-2.vue')
    },
    {
      path: '/busses',
      name: 'busses',
      component: () => import('@/views/busses.vue')
    },
    {
      path: '/ques',
      name: 'ques',
      component: () => import('@/views/ques.vue')
    },
    {
      path: '/arrange-problem',
      name: 'arrange-problem',
      component: () => import('@/views/arrange-problem.vue')
    },
    {
      path: '/trips',
      name: 'trips',
      component: () => import('@/views/trips.vue')
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('@/views/stats.vue')
    },
    {
      path: '/send',
      name: 'send',
      component: () => import('@/views/users/send.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings.vue')
    },
    {
      path: '/devs',
      name: 'devs',
      component: () => import('@/views/devs.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins.vue')
    },
    {
      path: '/camps',
      name: 'camps',
      component: () => import('@/views/camps.vue')
    },
    {
      path: '/scan',
      name: 'scan',
      component: () => import('@/views/scan.vue')
    },
    {
      path: '/arrange-bus',
      name: 'arrange-bus',
      component: () => import('@/views/arrange-bus.vue')
    },
    {
      path: '/arrange-camp',
      name: 'arrange-camp',
      component: () => import('@/views/arrange-camp.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/list.vue')
    },
    {
      path: '/users/add',
      name: 'users-add',
      component: () => import('@/views/users/add.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
